<div class="ticket-card" [class.ticket-card--extend]="type === 'extend'" [class.ticket-card--loss]="ticket.is_won === false">
  <div class="ticket-card__content" [ngStyle]="{'background-image':  ticket.is_won === true && type === 'extend' && ticket.image ? 'url(' + ticket.image + ')' : ''}">
    <ng-container *ngIf="ticket.is_won === true && type !== 'extend'">
      <strong>{{ ticket.market_value | currency:'GBP'}}</strong>
      Site Credit
    </ng-container>
    <ng-container *ngIf="ticket.is_won === false">
      {{ ticket.ticket_number }}
    </ng-container>
  </div>

  <div class="ticket-card__footer" *ngIf="ticket.is_won !== false">{{ ticket.ticket_number}}</div>
</div>